import React, { ReactElement } from 'react';
import SanityImage from '../../components/NewSanityImage';
import { Colors, Hero, Box } from '@parsleyhealth/cilantro-ui';
import { SanityBlock } from 'graphql-types';
import BlockContent from '../BlockContent';
import { BlogGuideForm } from './blog-guide-form';

interface Props {
  title: string;
  description: SanityBlock[];
  image: any;
  ctaText: string;
  backgroundColor: keyof Colors | string;
  hubspotId: string;
  guideId: string;
}

export function BlogGuideHero(props: Props): ReactElement {
  const {
    title,
    description,
    backgroundColor,
    image,
    ctaText,
    hubspotId,
    guideId
  } = props;
  return (
    <Box backgroundColor={backgroundColor}>
      <Hero
        isBlogGuide
        split
        boxedText={false}
        textColor="white"
        preHeading="Guides"
        heading={title}
        description={<BlockContent blocks={description} />}
        image={
          image && (
            <SanityImage
              image={image}
              imageSizes={['100vw', '50vw']}
              coverContainer
            />
          )
        }
        postDescription={
          <BlogGuideForm
            guideId={guideId}
            hubspotId={hubspotId}
            ctaText={ctaText}
          />
        }
      />
    </Box>
  );
}
