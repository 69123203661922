import React, { ReactElement } from 'react';
import SanityImage from '../NewSanityImage';
import { SanityBasicImage } from 'graphql-types';
import { Box, Text, getResponsiveProp } from '@parsleyhealth/cilantro-ui';
import { BlogGuideForm } from './blog-guide-form';

interface Props {
  color: string;
  ctaText: string;
  description: React.ReactNode;
  image: SanityBasicImage;
  title: string;
  hubspotId: string;
}

export function BlogGuideResultsCard({
  color,
  ctaText,
  description,
  image,
  title,
  hubspotId
}: Props): ReactElement {
  const guideId = `get-guide-module-results-${title.replace(/ /g, '')}`;

  return (
    <Box
      borderRadius="xxs"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      width="100%"
    >
      <Box width="100%" height={getResponsiveProp('230px', '500px')}>
        {image && (
          <SanityImage
            image={image}
            imageSizes={['100vw', '50vw']}
            coverContainer
          />
        )}
      </Box>
      <Box
        backgroundColor={color}
        color="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        py={getResponsiveProp('s', 'l')}
        px={getResponsiveProp('s', 'xxl')}
      >
        <Text variant="heading2" textAlign="center">
          {title}
        </Text>
        <Box mb="l" textAlign="center">
          {description}
        </Box>
        <Box component="section" width="100%">
          <BlogGuideForm
            guideId={guideId}
            hubspotId={hubspotId}
            ctaText={ctaText}
            buttonVariant="borderedWhite"
          />
        </Box>
      </Box>
    </Box>
  );
}
