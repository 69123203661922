import React, { ReactElement, useState } from 'react';
import { track } from '@parsleyhealth/cilantro-track';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './blog-guide-form-schema';
import { Textfield } from '@ph-bit/design.ui.inputs.textfield';
import {
  Pill,
  PillColorTheme,
  PillVariant
} from '@ph-bit/design.ui.buttons.pill';
import clsx from 'clsx';

type FormData = {
  email: string;
  guideId: string;
};

interface Props {
  hubspotId: string;
  ctaText: string;
  guideId: string;
  buttonVariant?: 'primary' | 'borderedWhite';
}

const spanClasses = 'text-ph-white mt-2 text-xs';

export function BlogGuideForm({
  ctaText,
  guideId,
  buttonVariant = 'primary'
}: Props): ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(schema)
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = (data: FormData) => {
    track('Guide requested', {
      guide: guideId,
      email: data.email
    });
    setIsSubmitted(true);
  };

  const cta = ctaText || 'Get Started';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={clsx('w-full flex flex-col', {
          'items-start': buttonVariant === 'primary',
          'items-center': buttonVariant !== 'primary'
        })}
      >
        <label htmlFor={guideId}>
          <span className={spanClasses}>Download the guide</span>
        </label>
        <div className="mb-6 w-full">
          <Textfield
            name="email"
            label="Email"
            required
            placeholder="Email"
            {...register('email')}
            validationMessage={errors.email?.message as string}
          />
        </div>
        <div>
          {isSubmitted ? (
            <span className={spanClasses}>Form submitted successfully!</span>
          ) : (
            <Pill
              className="flex-center"
              variant={PillVariant.primary}
              colorTheme={PillColorTheme.sage}
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              {cta}
            </Pill>
          )}
        </div>
      </div>
    </form>
  );
}
