import React from 'react';
import { graphql } from 'gatsby';
import { SanityBlogGuide } from 'graphql-types';
import {
  Box,
  getResponsiveProp,
  H3,
  TextBlockSection,
  theme
} from '@parsleyhealth/cilantro-ui';
import { default as SEO } from '../components/SEO';
import BlogLayout from '../components/Blog/blog-layout';
import BlockContent from '../components/BlockContent';
import { BlogGuideHero } from '../components/Blog/blog-guide-hero';
import { BlogGuideFeatureCard } from '../components/Blog/blog-guide-feature-card';
import { BlogGuideSteps } from '../components/Blog/blog-guide-steps';
import { BlogGuideResultsCard } from '../components/Blog/blog-guide-results-card';
import { BlogGuideCarouselCards } from '../components/Blog/blog-guide-carousel-cards';

interface Props {
  data: {
    sanityBlogGuide: SanityBlogGuide;
    allSanityBlogGuide: {
      edges: {
        node: SanityBlogGuide;
      }[];
    };
  };
}

const CenterWebWrapper = ({ children }) => (
  <Box display="flex" flexDirection="column" alignItems="center" m="s">
    {children}
  </Box>
);

const Module = ({ children }) => (
  <Box width="100%" maxWidth="760px" my={getResponsiveProp('xs', 'l')}>
    {children}
  </Box>
);

export default function BlogGuideTemplate({ data }: Props) {
  const {
    title,
    metaTitle,
    metaDescription,
    metaImage,
    heroDescription,
    color,
    image,
    mainDescription,
    ctaText,
    features,
    featuresTitle,
    results,
    hubspotFormId,
    noindex,
    guideId
  } = data?.sanityBlogGuide;
  const result = results[0];
  const guides = data?.allSanityBlogGuide?.edges.map(item => item.node);

  let seoImage = null;
  if (metaImage) {
    seoImage = metaImage;
  } else if (image) {
    seoImage = image;
  }

  return (
    <>
      <SEO
        pageTitle={metaTitle ? metaTitle : title}
        pageDescription={
          metaDescription
            ? metaDescription
            : heroDescription[0]?.children[0].text
        }
        pageImage={seoImage}
        noindex={noindex}
      />
      <BlogLayout paddingTop marginTop={false}>
        {/* Hero module */}
        <BlogGuideHero
          description={heroDescription}
          image={image}
          title={title}
          ctaText={ctaText}
          backgroundColor={color.hex}
          hubspotId={hubspotFormId}
          guideId={guideId}
        />
        {/* Description module */}
        <TextBlockSection
          backgroundColor={theme.colors.backgroundAltBlue}
          title={<BlockContent blocks={mainDescription[0]} />}
          description={
            <Box color={theme.colors.textGray}>
              <BlockContent blocks={mainDescription[1]} />
            </Box>
          }
          alignment="center"
          backgroundImage={null}
        />
        <CenterWebWrapper>
          {/* Features module */}
          <Module>
            <H3 variant="heading2" mb="l">
              {featuresTitle}
            </H3>
            {features.map((feature, index) => (
              <BlogGuideFeatureCard
                key={`Guide-feature-${index}`}
                title={feature?.title}
                description={<BlockContent blocks={feature?.description} />}
                image={feature.image}
              />
            ))}
          </Module>
          {/* Steps module */}
          <Module>
            <H3 variant="heading2" mb="l">
              How it works
            </H3>
            <BlogGuideSteps
              title="Enter your email"
              description="Enter your email address to register for this FREE video masterclass."
              step={1}
            />
            <BlogGuideSteps
              title="Get immediate access"
              description="Check your inbox for the video."
              step={2}
            />
            <BlogGuideSteps
              title="Keep an eye on your inbox"
              description="Watch out for a special offer from Parsley Health!"
              step={3}
            />
          </Module>
          {/* Results module */}
          {/* TODO: Review sanity data */}
          <Module>
            {result.color ? (
              <BlogGuideResultsCard
                color={result?.color?.hex}
                ctaText={result?.ctaText}
                description={<BlockContent blocks={result?.description} />}
                image={result?.image}
                title={result?.title}
                hubspotId={hubspotFormId}
              />
            ) : (
              <BlogGuideResultsCard
                color={color.hex}
                ctaText={ctaText}
                description={
                  'Learn how to take control of your gut health and get simple tips for improving your digestive symptoms starting today.'
                }
                image={image}
                title={'Join us!'}
                hubspotId={hubspotFormId}
              />
            )}
          </Module>
        </CenterWebWrapper>
        {/* Carrosel */}
        <BlogGuideCarouselCards guides={guides} />
      </BlogLayout>
    </>
  );
}

export const query = graphql`
  fragment BlogGuideHero on SanityBlogGuide {
    title
    metaTitle
    metaDescription
    metaImage {
      ...MetaImage
    }
    image {
      _type
      ...SanityImageFragment
    }
    color {
      hex
    }
    heroDescription {
      _type
      children {
        _type
        text
      }
    }
    slug {
      current
    }
  }
  fragment BlogGuideMainDescription on SanityBlogGuide {
    mainDescription {
      _type
      children {
        _type
        text
      }
      style
    }
    ctaText
  }
  fragment BlogGuideSteps on SanityBlogGuide {
    steps {
      title
      description {
        _type
        children {
          _type
          text
        }
      }
    }
  }
  fragment BlogGuideFeature on SanityBlogGuide {
    featuresTitle
    features {
      title
      description {
        _type
        children {
          _type
          text
        }
      }
      image {
        _type
        ...SanityImageFragment
      }
    }
  }
  fragment BlogGuideResults on SanityBlogGuide {
    results {
      image {
        _type
        ...SanityImageFragment
      }
      color {
        hex
      }
      title
      description {
        _type
        children {
          _type
          text
        }
      }
      ctaText
    }
  }

  query BlogGuideQuery($sanityID: String) {
    sanityBlogGuide(_id: { eq: $sanityID }) {
      ...BlogGuideHero
      ...BlogGuideMainDescription
      ...BlogGuideSteps
      ...BlogGuideFeature
      ...BlogGuideResults
      hubspotFormId
      guideId
      noindex
    }
    allSanityBlogGuide(limit: 10) {
      edges {
        node {
          ...BlogGuideHero
        }
      }
    }
  }
`;
