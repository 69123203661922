import React, { ReactElement } from 'react';
import SanityImage from '../NewSanityImage';
import { SanityBasicImage } from 'graphql-types';
import { Box, getResponsiveProp, H3, theme } from '@parsleyhealth/cilantro-ui';

interface BlogGuideFeatureCardProps {
  title: string;
  description: React.ReactNode;
  image: SanityBasicImage;
}

export function BlogGuideFeatureCard({
  image,
  title,
  description
}: BlogGuideFeatureCardProps): ReactElement {
  return (
    <Box
      mb="s"
      width="100%"
      flexDirection={getResponsiveProp('column', 'row')}
      backgroundColor={theme.colors.lightGrayBackground}
      display="inline-flex"
      overflow="hidden"
      borderRadius="xxs"
    >
      <Box width={getResponsiveProp('100%', '250px')} height="200px">
        {image && <SanityImage image={image} coverContainer />}
      </Box>
      <Box
        p="l"
        flex="1 1 0%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <H3>{title}</H3>
        {description}
      </Box>
    </Box>
  );
}
