import React, { ReactElement } from 'react';
import { Box, Text } from '@parsleyhealth/cilantro-ui';

interface BlogGuideFeatureCardProps {
  title: string;
  description: React.ReactNode;
  step: 1 | 2 | 3;
}

export function BlogGuideSteps({
  step,
  title,
  description
}: BlogGuideFeatureCardProps): ReactElement {
  return (
    <Box mb="xl" width="100%" display="inline-flex" alignItems="center" gap="m">
      <Box
        display="inline-flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        overflow="hidden"
        bg="success"
        height="60px"
        width="60px"
      >
        <Text variant="pSerifLarge" mt="xxs" color="white">
          {step}
        </Text>
      </Box>
      <Box
        display="flex"
        flex="1 1 0%"
        flexDirection="column"
        justifyContent="center"
      >
        <Text variant="pExtraLarge">{title}</Text>
        <Text>{description}</Text>
      </Box>
    </Box>
  );
}
