import React, { ReactElement } from 'react';
import { navigate } from 'gatsby';
import { SanityBlogGuide } from 'graphql-types';
import {
  Box,
  Button,
  getResponsiveProp,
  Text,
  theme
} from '@parsleyhealth/cilantro-ui';
import SanityImage from '../NewSanityImage';
import BlockContent from '../BlockContent';
import { getGuideUrl } from '../../lib/blog-utils';

interface Props {
  guide: SanityBlogGuide;
}

export function BlogGuideCard({ guide }: Props): ReactElement {
  const { image, title, heroDescription, color, slug } = guide;

  const handleOnButtonClick = () => {
    const url = getGuideUrl(slug?.current);
    navigate(url);
  };

  return (
    <Box
      backgroundColor={theme.colors.lightGrayBackground}
      borderRadius="xxs"
      mx={getResponsiveProp('s', 'xxs')}
      overflow="hidden"
    >
      <Box width="100%" height="200px">
        <SanityImage image={image} coverContainer />
      </Box>
      <Box
        backgroundColor={color?.hex}
        width="100%"
        color="white"
        p="m"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
      >
        <Text color="white" variant="heading3">
          {title}
        </Text>
        <Text color="white" mb="m">
          <BlockContent blocks={heroDescription} />
        </Text>
        <Button
          variant="underlinedLink"
          color="white"
          onClick={handleOnButtonClick}
        >
          <span role="img" aria-label="Emoji - Victory Hand or Peace Sign">
            ✌️
          </span>{' '}
          FREE GUIDE
        </Button>
      </Box>
    </Box>
  );
}
