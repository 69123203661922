import React, { ReactElement } from 'react';
import { Box, Button, getResponsiveProp, H3 } from '@parsleyhealth/cilantro-ui';
import { SanityBlogGuide } from 'graphql-types';
import { BlogGuideCard } from './blog-guide-card';
import Carousel from 'react-multi-carousel';

interface Props {
  guides: SanityBlogGuide[];
}

export function BlogGuideCarouselCards({ guides }: Props): ReactElement {
  return (
    <Box
      backgroundColor="backgroundDarkBlue"
      position="relative"
      py={getResponsiveProp('s', 'xxxl')}
      pb={getResponsiveProp('none', 'xl')}
      pt={getResponsiveProp('s', 'none')}
    >
      <Box
        display="flex"
        flexDirection={getResponsiveProp('column', 'row')}
        justifyContent={getResponsiveProp('center', 'space-between')}
        alignItems="center"
        maxWidth={getResponsiveProp('none', '1380px')}
        py={getResponsiveProp('s', 'xl')}
        m="auto"
      >
        <H3 color="white" variant="heading2" textAlign="center">
          Parsley Health Guides
        </H3>
        <Button href="/blog/guides">See All Guides</Button>
      </Box>
      <Box maxWidth="1380px" m="auto">
        <Carousel
          slidesToSlide={4}
          infinite
          draggable
          arrows={false}
          responsive={{
            Desktop: {
              breakpoint: {
                max: 4000,
                min: 1024
              },
              items: 4,
              partialVisibilityGutter: 0
            },
            mobile: {
              breakpoint: {
                max: 500,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 0
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 500
              },
              items: 2,
              partialVisibilityGutter: 0
            }
          }}
        >
          {guides &&
            guides.map((guide, index) => (
              <BlogGuideCard key={`guide-${index}`} guide={guide} />
            ))}
        </Carousel>
      </Box>
    </Box>
  );
}
